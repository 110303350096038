import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import Colors from './Colors';
import styled from '@emotion/styled';

const FeaturedSpan = styled('span')`
  margin: 0.25rem;
  color: #a9a4a4;
  font-size: 0.85rem;
  @media (max-width: 820px) {
    display: block;
  }
`;

const FeaturedLink = styled(Link)`
  color: ${Colors.light};
  &:hover {
    color: #a9a4a4;
  }
`;

const FeaturedPostBar = () => {
  return (
    <div
      css={css`
        padding: 0.25rem 0;
        background: #5d6669;
        text-align: center;
      `}
      className={'grid-featured'}
    >
      <div>
        <FeaturedSpan>
          [{' '}
          <FeaturedLink to={'/tv-movies/sons-of-thunder-season-one-recap'}>
            Sons of Thunder S1 Review
          </FeaturedLink>{' '}
          ]
        </FeaturedSpan>
        <FeaturedSpan>
          [{' '}
          <FeaturedLink to={'/digital-service-providers/vidangel-review-overview'}>
            VidAngel Review Series
          </FeaturedLink>{' '}
          ]
        </FeaturedSpan>
        <FeaturedSpan>
          [{' '}
          <FeaturedLink to={'/content-calendar/'}>
            Upcoming Content Calendar
          </FeaturedLink>{' '}
          ]
        </FeaturedSpan>
      </div>
    </div>
  );
};

export default FeaturedPostBar;
