import React from 'react';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBible,
  faMobileAlt,
  faVideo,
  faStoreAlt,
  faNewspaper,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import Colors from './Colors';
import Category from '../constants/CategoryMap';

const buildLink = (to, icon, color, text) => (
  <Link
    css={css`
      color: ${color};
    `}
    to={to}
  >
    <FontAwesomeIcon
      css={css`
        color: ${color} !important;
        margin-right: 0.5rem;
      `}
      icon={icon}
      size={'sm'}
      width="18"
    />
    {text}
  </Link>
);

const Hero = ({ jumbo }) => {
  return (
    <div
      css={css`
        padding: 1rem 0;
        background: #dedede;
        display: ${jumbo ? '' : 'none'};
      `}
      className={'grid-jumbo'}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          > span {
            list-style: none;
            margin-right: 1.5rem;
            text-transform: uppercase;
          }
        `}
      >
        <span
          css={css`
            color: ${Colors.categoryApps};
          `}
        >
          {buildLink(
            Category.urls['apps'],
            faMobileAlt,
            Colors.categoryApps,
            Category.keys.apps
          )}
        </span>

        <span
          css={css`
            color: ${Colors.categoryDigital};
          `}
        >
          {buildLink(
            Category.urls['digital'],
            faLaptop,
            Colors.categoryDigital,
            Category.keys.digital
          )}
        </span>

        <span
          css={css`
            color: ${Colors.categoryTv};
          `}
        >
          {buildLink(
            Category.urls['tv'],
            faVideo,
            Colors.categoryTv,
            Category.keys.tv
          )}
        </span>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 0.5rem;
          > span {
            list-style: none;
            margin-right: 1.5rem;
            text-transform: uppercase;
          }
        `}
      >
        <span
          css={css`
            color: ${Colors.categoryLife};
          `}
        >
          {buildLink(
            Category.urls['life'],
            faBible,
            Colors.categoryLife,
            Category.keys.life
          )}
        </span>

        <span
          css={css`
            color: ${Colors.categoryStores};
          `}
        >
          {buildLink(
            Category.urls['stores'],
            faStoreAlt,
            Colors.categoryStores,
            Category.keys.stores
          )}
        </span>
        <span
          css={css`
            color: ${Colors.categoryCb};
          `}
        >
          {buildLink(
            Category.urls['cb'],
            faNewspaper,
            Colors.categoryCb,
            Category.keys.cb
          )}
        </span>
      </div>
    </div>
  );
};

export default Hero;
