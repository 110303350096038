import React from 'react';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';
import Header from './Header';
import useSiteMetadata from '../hooks/use-sitemetadata';
import Footer from './Footer';
import Nav from './Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Colors from './Colors';
import Hero from './Hero';
import FeaturedPostBar from './FeaturedPostBar';
import CalloutBar from './CalloutBar';

// Everything I put here is global to the site.

const Layout = ({
  children,
  jumbo,
  pageTitle,
  pageDescription,
  gridMainExtraClasses,
  image,
  canonicalurl,
  ogpagetype
}) => {
  const { title, description } = useSiteMetadata();
  const mainClasses = gridMainExtraClasses
    ? 'grid-main ' + gridMainExtraClasses
    : 'grid-main';

  const currentTitle = pageTitle ? pageTitle + ' - ChristianBytes.com' : title;
  const currentDescription = pageDescription ? pageDescription : description;
  const currentImage = image && image.publicURL ? "https://www.christianbytes.com" + image.publicURL : '';
  const currentURL = canonicalurl ? canonicalurl : false;

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          * + * {
            margin-top: 0;
          }

          html,
          body {
            margin: 0;
            color: #000;
            background: ${Colors.light};
            font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 18px;
            line-height: 1.4;
            width: 100%;

            /* remove margin for main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #222;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #222;
            }

            li {
              margin-top: 0.25rem;
            }
          }
          button:focus {
            outline: 0;
          }

          .cb-main {
            color: ${Colors.main};
          }
          .cb-secondary {
            color: ${Colors.secondary};
          }
          .cb-light {
            color: ${Colors.light};
          }
          .cb-unused {
            color: ${Colors.darkHighlight};
          }
          .cb-unused2 {
            color: ${Colors.lightHighlight};
          }

          .cb-main-background {
            background: ${Colors.main};
          }
          .cb-secondary-background {
            background: ${Colors.secondary};
          }
          .cb-light-background {
            background: ${Colors.light};
          }
          .cb-unused-background {
            background: ${Colors.darkHighlight};
          }
          .cb-unused2-background {
            background: ${Colors.lightHighlight};
          }

          .grid-header {
            grid-area: header;
          }

          .grid-footer {
            grid-area: footer;
          }

          .grid-main {
            grid-area: main;
          }

          .grid-main.armory-override {
            width: auto !important;
          }

          .grid-jumbo {
            grid-area: jumbo;
            display: none;
          }

          .grid-featured {
            grid-area: featured;
          }

          .grid-callout {
            grid-area: callout;
          }

          .grid-nav {
            grid-area: nav;
            display: none;
          }

          .header-nav {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: auto;
            align-items: center;
            height: 70px;
          }

          .logo {
            grid-column: 1/6;
            margin-left: 2rem;
          }

          .logo-text-size {
            font-size: 2rem;
          }

          .hamburger {
            grid-column: 6/7;
            justify-self: end;
          }

          #gatsby-focus-wrapper {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-template-areas:
              'header'
              'callout'
              'featured'
              'main'
              'footer';
          }

          .fixed-body {
            position: fixed;
          }

          .christian-armory-nav-link {
            display: inline-block;
            margin-right: 0.5rem;
          }

          a.christian-armory-nav-link,
          .christian-armory-nav-link-main a {
            color: ${Colors.lightHighlight};
          }

          .christian-armory-item-link {
            color: ${Colors.main};
          }

          .main-link,
          main a {
            color: ${Colors.main};
          }

          a.christian-armory-nav-link:hover,
          .christian-armory-item-link:hover,
          .christian-armory-nav-link-main a:hover {
            color: ${Colors.main};
          }

          @media (min-width: 100px) {
            .card-columns {
              -webkit-column-count: 1;
              -moz-column-count: 1;
              column-count: 1;
            }
          }

          @media (min-width: 700px) {
            .grid-jumbo,
            .grid-nav {
              display: block;
            }

            #gatsby-focus-wrapper {
              display: grid;
              grid-template-columns: auto;
              grid-template-rows: auto;
              grid-template-areas:
                'header'
                'nav'
                'callout'
                'featured'
                'jumbo'
                'main'
                'footer';
            }
          }

          .armory-card-layout {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
          }

          @media (max-width: 440px) {
            .logo-text-size {
              font-size: 1.5rem;
            }

            .armory-card-layout {
              -webkit-column-count: 1;
              -moz-column-count: 1;
              column-count: 1;
            }
          }

          @media (min-width: 800px) {
            .card-columns {
              -webkit-column-count: 2;
              -moz-column-count: 2;
              column-count: 2;
            }

            .armory-card-layout {
              -webkit-column-count: 2;
              -moz-column-count: 2;
              column-count: 2;
            }
          }

          @media (min-width: 1000px) {
            .card-columns {
              -webkit-column-count: 3;
              -moz-column-count: 3;
              column-count: 3;
            }

            .armory-card-layout {
              -webkit-column-count: 2;
              -moz-column-count: 2;
              column-count: 2;
            }
          }

          @media (min-width: 1200px) {
            .armory-card-layout {
              -webkit-column-count: 3;
              -moz-column-count: 3;
              column-count: 3;
            }

            .grid-main {
              width: 80% !important;
            }
          }

          @media (min-width: 1400px) {
            .armory-card-layout {
              -webkit-column-count: 4;
              -moz-column-count: 4;
              column-count: 4;
            }

            .grid-main {
              width: 70% !important;
            }
          }
        `}
      />

      <Helmet>
        <html lang="en" />
        <title>{currentTitle}</title>
        <meta name="description" content={currentDescription} />
        <meta property="fb:app_id" content="560773418111611" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@BytesChristian" />
        {
          ogpagetype ?
            <meta property="og:type" content="article" /> :
            <meta property="og:type" content="website" />
        }
        <meta property="og:title" content={currentTitle} />
        <meta property="og:description" content={currentDescription} />
        <meta property="og:image" content={currentImage} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        {
          currentURL ?
          <meta property="og:url" content={currentURL} />
          : ''
        }
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Header />
      <Nav />
      <Hero jumbo={jumbo} />
      <CalloutBar/>
      <FeaturedPostBar />
      <main
        className={mainClasses}
        css={css`
          width: 90%; //calc(100% - 64px);
          position: relative;
          max-width: 100vw;
          margin: 2rem auto;
          color: #58574f;
          h1,
          h2,
          h3,
          h4,
          h5 {
            color: #5d6769;
            margin: 1rem 0;
          }
          strong {
            color: #5c645c;
          }
        `}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
