import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import Colors from './Colors';
import styled from '@emotion/styled';

const FeaturedSpan = styled('span')`
  margin: 0.25rem;
  color: ${Colors.light};
  font-size: 0.85rem;
  @media (max-width: 820px) {
    display: block;
  }
`;

const FeaturedLink = styled(Link)`
  color: ${Colors.light};
  font-weight: bold;
  white-space: nowrap;
  &:hover {
    color: ${Colors.main};
  }
`;

const CalloutBar = () => {
  return (
    <div
      css={css`
        padding: 0.25rem 0;
        background: #9a8465;
        text-align: center;
        color: ${Colors.light};
      `}
      className={'grid-callout'}
    >
      <div>
        <FeaturedSpan>
          {' '}
          Find <strong css={css`color:${Colors.light};`}>Christian-Based</strong> Bible Apps, Podcasts, Radio Apps, Movies, News, Music Playlists, YouTube Channels, Twitter Accounts & more in the &nbsp;
          <FeaturedLink to={'/armory'}>
           [ ARMORY ]
          </FeaturedLink>{' '}
        </FeaturedSpan>
      </div>
    </div>
  );
};

export default CalloutBar;
