import React from 'react';
import { css } from '@emotion/core';
import Colors from './Colors';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import {
  faFacebookSquare,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import URLMap from '../constants/URLMap';

const InternalFooterLink = styled(Link)`
  color: ${Colors.light};
  margin-right: 1.5rem;
  &:hover {
    color: ${Colors.main};
  }
`;

const currentYear = new Date().getFullYear();
const copyrightYears = (currentYear === 2020) ? '2020' : '2020 - ' + currentYear;

const Footer = () => (
  <div
    className={'grid-footer'}
    css={css`
      background: ${Colors.secondary};
      margin: 2rem 0 0 0;
      width: 100%;
    `}
  >
    <div
      css={css`
        width: 70%;
        margin: 0 auto;
        @media (max-width: 700px) {
          width: 90%;
        }
      `}
    >
      <div
        css={css`
          text-align: left;
          margin: 1rem 0;
        `}
      >
        <InternalFooterLink to="/jesus/">Jesus</InternalFooterLink>
        <InternalFooterLink to="/armory/">Armory</InternalFooterLink>
        <InternalFooterLink to="/podcast/">Podcast</InternalFooterLink>
        <InternalFooterLink
          to="/about/"
          css={css`
            @media (max-width: 700px) {
              ::before {
                content: '\\A';
                white-space: pre;
              }
            }
          `}
        >
          About & Contact
        </InternalFooterLink>
      </div>
      <div
        css={css`
          width: 100%;
          border-top: 1px ${Colors.light} solid;
        `}
      ></div>

      <div
        css={css`
          display: grid;
          grid-template-areas: 'site social scripture';
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 0.25rem 1rem;
          margin: 0 0 1.5rem;
          @media (max-width: 600px) {
            display: block;
          }
        `}
      >
        <div
          css={css`
            grid-area: site;
            align-self: start;
            margin-top: 1rem;
          `}
        >
          <h5
            className={'cb-light'}
            css={css`
              font-weight: bold;
              margin-bottom: 0;
            `}
          >
            <Link
              to="/"
              css={css`
                color: ${Colors.light};
                &:hover {
                  color: ${Colors.main};
                }
              `}
            >
              Christian Bytes
            </Link>
          </h5>
          <p
            className={'cb-light'}
            css={css`
              margin-top: 0;
            `}
          >
            Digital-Age Christian
          </p>
          <p
            css={css`
              color: ${Colors.light};
            `}
          >
            A light in the darkness of the Internet aiming to help people like
            you build & enjoy a more fruitful Christ-Centered life.
          </p>
        </div>

        <div
          css={css`
            grid-area: social;
            align-self: start;
            margin-top: 1rem;
          `}
        >
          <p
            css={css`
              font-weight: 700;
              color: ${Colors.light};
            `}
          >
            STAY IN TOUCH
          </p>
          <p
            css={css`
              margin-top: 0.5rem;
            `}
          >
            <a
              css={css`color: ${Colors.light}; &:hover{color:${Colors.main};`}
              href="mailto:readers@christianbytes.com"
            >
              readers@christianbytes.com
            </a>
          </p>
          <p>
            <a
              href={URLMap.Social.facebook.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                css={css`
                  color: ${Colors.light};
                  margin-right: 0.5rem;
                  font-size: 2rem;
                  &:hover {
                    color: ${Colors.main};
                  }
                `}
                icon={faFacebookSquare}
                size={'sm'}
                width="28"
              />
            </a>
            <a
              href={URLMap.Social.twitter.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                css={css`
                  color: ${Colors.light};
                  margin-right: 0.5rem;
                  font-size: 2rem;
                  &:hover {
                    color: ${Colors.main};
                  }
                `}
                icon={faTwitter}
                size={'sm'}
                width="28"
              />
            </a>
          </p>
        </div>
        <div
          css={css`
            grid-area: scripture;
            align-self: start;
            margin-top: 1rem;
          `}
        >
          <p
            css={css`
              font-weight: 700;
              color: ${Colors.light};
              text-transform: uppercase;
            `}
          >
            Philippians 4:13
          </p>

          <p
            css={css`
              font-style: italic;
              color: ${Colors.light};
            `}
          >
            I can do all things through Christ, who strengthens me.
          </p>
        </div>
      </div>

      <div
        css={css`
          font-size: 0.75rem;
          margin: 0.5rem 0;
          color: ${Colors.light};
          display: flex;
          @media (max-width: 600px) {
            display: block;
          }
        `}
      >
        <p css={
          css`
            margin-right: .5rem;
          `
        }>
          &copy;{copyrightYears} ChristianBytes.com. All rights reserved.
        </p>
        <p css={
          css`
          font-weight: bold;
          @media (max-width: 600px) {
            margin-top: -.5rem;
          }
          `
        }>
         Jesus Lives!
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
