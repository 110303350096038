import React from 'react';
import { css } from '@emotion/core';
import Colors from './Colors';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Pages from '../constants/PagesMap';

const NavLink = styled(Link)`
  color: ${Colors.main};
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || 'bold'};
  line-height: 1;
  text-decoration: none;

  &:hover {
    color: ${Colors.light};
    text-decoration: none;
  }

  &.current-page {
    color: ${Colors.light} !important;
    border-bottom: 2px solid ${Colors.light};
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const Nav = () => (
  <>
    <nav
      className={'grid-nav'}
      css={css`
        margin: 0;
        background: ${Colors.secondary};
        text-align: center;
        padding: 0.25rem;
      `}
    >
      <NavLink to={Pages.urls['armory']} activeClassName="current-page">
        {Pages.keys['armory']}
      </NavLink>
      <span
        css={css`
          margin: 0 0.5rem;
        `}
      >
        /
      </span>
      <NavLink to={Pages.urls['jesus']} activeClassName="current-page">
        {Pages.keys['jesus']}
      </NavLink>
      <span
        css={css`
          margin: 0 0.5rem;
        `}
      >
        /
      </span>

      <NavLink to={Pages.urls['about']} activeClassName="current-page">
        {Pages.keys['about']}
      </NavLink>
      <span
        css={css`
          margin: 0 0.5rem;
        `}
      >
        /
      </span>
      <NavLink to={Pages.urls['podcast']} activeClassName="current-page">
        {Pages.keys['podcast']}
      </NavLink>

    </nav>
  </>
);

export default Nav;
