import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/core';
import { faBars, faWindowClose } from '@fortawesome/free-solid-svg-icons';

const StyledBurger = styled('button')`
  cursor: pointer;
  position: relative;
  right: 20px;
  padding: 0;
  z-index: 999;
  outline: none;
  outline-color: none;
`;

const Hamburger = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false;

  // This check is here for Webpack because WINDOW/DOCUMENT doesn't exist on build for server-side builds.
  // https://www.gatsbyjs.org/docs/debugging-html-builds/#fixing-third-party-modules
  if (typeof window !== 'undefined') {
    if (open) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = '';
    }
  }

  return (
    <StyledBurger
      css={css`
        background: none;
        border: 0;
        outline: none;
      `}
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
      {...props}
    >
      {open ? (
        <FontAwesomeIcon
          css={css`
            margin-right: 0.5rem;
            color: ${Colors.main};
          `}
          icon={faWindowClose}
          size={'lg'}
          width="28"
        />
      ) : (
        <FontAwesomeIcon
          css={css`
            margin-right: 0.5rem;
            color: ${Colors.light};
          `}
          icon={faBars}
          size={'lg'}
          width="28"
        />
      )}
    </StyledBurger>
  );
};

export default Hamburger;
