import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Colors from './Colors';
import { Link } from 'gatsby';
import CategoryMap from '../constants/CategoryMap';
import Pages from '../constants/PagesMap';

const StyledH1 = styled('h1')`
  color: ${Colors.light};
  border-bottom: 1px solid ${Colors.main};
  margin: 0.25rem 0 1rem 0;
  @media (max-width: 430px) {
    margin: 0.5rem 0 0.5rem 0;
    font-size: 1.5rem;
  }

  @media (min-width: 700px) {
    margin: 1rem 0 0.5rem 0;
    font-size: 3rem;
  }

  @media (min-width: 1000px) {
    margin: 1.5rem 0 0.5rem 0;
    font-size: 3rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${Colors.main} !important;
  @media (max-width: 430px) {
    margin: 0;
  }
`;

const StyledP = styled('p')`
  margin: 0 0 0.25rem 0;

  @media (min-width: 700px) {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 1000px) {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
`;

const StyledMenu = styled('nav')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${Colors.secondary};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  a {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => Colors.secondary};
    text-decoration: none;
    transition: color 0.3s linear;
    &:hover {
      color: ${Colors.light};
    }
    @media (max-width: 430px) {
      font-size: 1rem;
    }

    @media (max-width: 380px) {
      font-size: 0.75rem;
    }

    @media (min-width: 700px) {
      font-size: 1.75rem;
    }
  }
`;

const printCategories = () => {
  return (
    <>
      {Object.keys(CategoryMap.keys).map(function(key, index) {
        return (
          <div key={index}>
            <StyledLink
              to={CategoryMap.urls[key]}
              css={css`
                color: ${CategoryMap.colors[key]} !important;
              `}
            >
              {CategoryMap.keys[key]}
            </StyledLink>
          </div>
        );
      })}
    </>
  );
};

const HamburgerOverlay = ({ open, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  return (
    <>
      <StyledMenu
        tabIndex={tabIndex}
        open={open}
        aria-hidden={!isHidden}
        {...props}
      >
        <StyledH1>SECTIONS</StyledH1>
        <StyledLink to={Pages.urls['armory']}>
          {Pages.keys['armory']}
        </StyledLink>
        <StyledP>
          Want to live a Christ-centered life? Visit the Armory to find great
          apps, podcasts, sites and more.
        </StyledP>
        <StyledLink to={Pages.urls['jesus']}>{Pages.keys['jesus']}</StyledLink>
        <StyledP>Who do you say I am?</StyledP>
        <StyledLink to={Pages.urls['about']}>{Pages.keys['about']}</StyledLink>

        <StyledP>
          What is CB all about and how can you contact me? Find out here.
        </StyledP>
        <StyledH1>BLOG CATEGORIES</StyledH1>
        {printCategories()}
      </StyledMenu>
    </>
  );
};

export default HamburgerOverlay;
