const PagesMap = {
  keys: {
    armory: 'Armory',
    jesus: 'Jesus',
    about: 'About & Contact',
    podcast: 'Podcast'
  },
  values: {
    Armory: 'armory',
    Jesus: 'jesus',
    'About & Contact': 'about',
    Podcast: 'podcast'
  },
  urls: {
    armory: '/armory/',
    jesus: '/jesus/',
    about: '/about/',
    podcast: '/podcast/'
  },
};

export default PagesMap;
