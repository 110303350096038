import { React, useState, useRef } from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import FocusLock from 'react-focus-lock';
import Colors from './Colors';
import Hamburger from './Hamburger';
import Menu from './HamburgerOverlay';

const logoHover = css({
  color: Colors.light,
  'text-decoration': 'none',
});

const logoLinkCSS = css({
  color: Colors.light,
  border: 0,
  ':hover,:focus': logoHover,
});

const Header = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = 'main-menu';

  return (
    <header
      className={'grid-header header-nav cb-main-background'}
      css={css`
        width: 100%;
      `}
    >
      <div className="logo">
        <Link to={'/'} css={logoLinkCSS}>
          <span
            className="logo-text-size"
            css={css`
              font-weight: bold;
            `}
          >
            <img
              alt="ChristianBytes.com Logo"
              css={css`
                height: 40px;
                width: 40px;
                margin-right: 10px;
              `}
              src="https://www.christianbytes.com/ChristianBytes-logo.png"
            />
            Christian
          </span>
          <span className="logo-text-size">Bytes</span>
        </Link>
      </div>
      <div className="hamburger" ref={node}>
        <FocusLock disabled={!open}>
          <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    </header>
  );
};

export default Header;
